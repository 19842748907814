var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `${
        _vm.showManageSubscription ? "" : "app flex-row align-items-center"
      }`,
    },
    [
      _vm.vueAppRunningProduction
        ? _c("div", { staticClass: "banner-testing-mode" })
        : _vm._e(),
      _vm.vueAppRunningProduction
        ? _c("div", { staticClass: "container-testing-mode" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.FormMSG(268, "Testing")) + "\n\t"),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container" },
        [
          !_vm.forgotPassword && !_vm.showManageSubscription
            ? _c(
                "div",
                [
                  _vm.showSuggestChrome
                    ? _c(
                        "b-row",
                        { staticClass: "justify-content-center" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c(
                                "b-alert",
                                {
                                  attrs: {
                                    variant: "warning",
                                    show: "",
                                    dismissible: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(250, "Use")) +
                                      " "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(251, "Chrome browser"))
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.FormMSG(252, "for better use")
                                      ) +
                                      ".\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "login justify-content-center" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "form", attrs: { md: "8" } },
                        [
                          _c(
                            "b-card-group",
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass: "card-border-blue-light p-4",
                                  class: `${
                                    !_vm.$screen.xl && !_vm.$screen.lg
                                      ? "card-full-fill"
                                      : "card-half-left-fill"
                                  }`,
                                  attrs: { "no-body": "" },
                                },
                                [
                                  _c(
                                    "b-card-body",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c("img", {
                                            staticClass: "navbar-brand-full",
                                            attrs: {
                                              src: "img/brand/thegreenshot-brand.png",
                                              width: "225",
                                              alt: "TheGreenShot",
                                            },
                                          }),
                                          _c(
                                            "h2",
                                            { staticClass: "pt-3 pb-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(101, " Login")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.signin.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "mb-4":
                                                  !_vm.authentificationFailed,
                                              },
                                            },
                                            [
                                              !_vm.askNewPassword
                                                ? _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "b-input-group",
                                                        { staticClass: "mb-3" },
                                                        [
                                                          _c(
                                                            "b-input-group-prepend",
                                                            [
                                                              _c(
                                                                "b-input-group-text",
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "User"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .PERCENT
                                                                            .color,
                                                                        size: 18,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("b-form-input", {
                                                            staticClass:
                                                              "form-control",
                                                            class: {
                                                              "border-gray":
                                                                _vm.$v.email
                                                                  .$error ===
                                                                false,
                                                              "br-none":
                                                                _vm.$v.email
                                                                  .$error ===
                                                                true,
                                                            },
                                                            attrs: {
                                                              type: "email",
                                                              placeholder:
                                                                _vm.FormMSG(
                                                                  103,
                                                                  "Email address"
                                                                ),
                                                              autocomplete:
                                                                "username email",
                                                              state:
                                                                !_vm.$v.email
                                                                  .$error,
                                                              disabled:
                                                                _vm.doubleAuthentication,
                                                            },
                                                            on: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                )
                                                                  return null
                                                                return _vm.handleKeyUpEnterEmail.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.$v.email
                                                                  .$model,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.$v
                                                                      .email,
                                                                    "$model",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "$v.email.$model",
                                                            },
                                                          }),
                                                          _vm.$v.email.$error
                                                            ? _c(
                                                                "b-input-group-append",
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.click.bottomleft",
                                                                            value:
                                                                              _vm.manageMessageEmailError(
                                                                                _vm
                                                                                  .$v
                                                                                  .email
                                                                              ),
                                                                            expression:
                                                                              "manageMessageEmailError($v.email)",
                                                                            modifiers:
                                                                              {
                                                                                click: true,
                                                                                bottomleft: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn-show-password border-red",
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "AlertTriangle",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.askNewPassword
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass: "text-muted",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1111,
                                                              "New password"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.askNewPassword
                                                ? _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "b-input-group",
                                                        [
                                                          _c(
                                                            "b-input-group-prepend",
                                                            [
                                                              _c(
                                                                "b-input-group-text",
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "Lock"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .PERCENT
                                                                            .color,
                                                                        size: 18,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("b-form-input", {
                                                            staticClass:
                                                              "form-control br-none",
                                                            class: {
                                                              "border-gray br-none":
                                                                _vm.$v.password
                                                                  .$error ===
                                                                false,
                                                              "is-invalid":
                                                                _vm.$v.password
                                                                  .$error,
                                                            },
                                                            attrs: {
                                                              type: _vm.showPassword
                                                                ? "text"
                                                                : "password",
                                                              placeholder:
                                                                _vm.FormMSG(
                                                                  104,
                                                                  "Password"
                                                                ),
                                                              state:
                                                                !_vm.$v.password
                                                                  .$error,
                                                              disabled:
                                                                _vm.doubleAuthentication,
                                                            },
                                                            on: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                )
                                                                  return null
                                                                return _vm.handleKeyUpEnterPassword.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.$v.password
                                                                  .$model,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.$v
                                                                      .password,
                                                                    "$model",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "$v.password.$model",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              !_vm.$v.password
                                                                .$error
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn-show-password",
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.showPassword =
                                                                              !_vm.showPassword
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      !_vm.showPassword
                                                                        ? _c(
                                                                            _vm.getLucideIcon(
                                                                              "Eye"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .PERCENT
                                                                                      .color,
                                                                                  size: 18,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.showPassword
                                                                        ? _c(
                                                                            _vm.getLucideIcon(
                                                                              "EyeOff"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .PERCENT
                                                                                      .color,
                                                                                  size: 18,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.$v.password
                                                                .$error === true
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.click.bottomleft",
                                                                            value:
                                                                              _vm.FormMSG(
                                                                                498,
                                                                                "Password is required"
                                                                              ),
                                                                            expression:
                                                                              "FormMSG(498, 'Password is required')",
                                                                            modifiers:
                                                                              {
                                                                                click: true,
                                                                                bottomleft: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn-show-password border-red",
                                                                      staticStyle:
                                                                        {
                                                                          "border-left":
                                                                            "none !important",
                                                                        },
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "AlertTriangle",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.doubleAuthentication
                                                        ? _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "col-12 bv-no-focus-ring col-form-label mt-3",
                                                              attrs: {
                                                                tabindex: "-1",
                                                                id: "aria-email-validation",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      53,
                                                                      "Enter email verfication code"
                                                                    )
                                                                  ) +
                                                                  " :\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.doubleAuthentication
                                                        ? _c(
                                                            "b-input-group",
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  ref: "otpCodeValidation",
                                                                  staticClass:
                                                                    "form-control br-none",
                                                                  model: {
                                                                    value:
                                                                      _vm.otpCode,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.otpCode =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "otpCode",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-input-group-append",
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.hover",
                                                                            modifiers:
                                                                              {
                                                                                hover: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn-show-password",
                                                                      attrs: {
                                                                        title:
                                                                          _vm.FormMSG(
                                                                            90,
                                                                            "Resend email verfication code"
                                                                          ),
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return _vm.sendValidationCode.apply(
                                                                              null,
                                                                              arguments
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "RotateCcw"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .PERCENT
                                                                                  .color,
                                                                              size: 18,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "b-form-group",
                                                    [
                                                      _c(
                                                        "b-input-group",
                                                        [
                                                          _c(
                                                            "b-input-group-prepend",
                                                            [
                                                              _c(
                                                                "b-input-group-text",
                                                                [
                                                                  _c(
                                                                    _vm.getLucideIcon(
                                                                      "Lock"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .PERCENT
                                                                            .color,
                                                                        size: 18,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("b-form-input", {
                                                            staticClass:
                                                              "form-control br-none",
                                                            class: {
                                                              "border-gray br-none":
                                                                _vm.$v
                                                                  .newPassword
                                                                  .$error ===
                                                                false,
                                                              "is-invalid":
                                                                _vm.$v
                                                                  .newPassword
                                                                  .$error,
                                                            },
                                                            attrs: {
                                                              id: "new-password",
                                                              type: _vm.showNewPassword
                                                                ? "text"
                                                                : "password",
                                                              autocomplete:
                                                                "off",
                                                              autofocus: "",
                                                              placeholder:
                                                                _vm.FormMSG(
                                                                  104,
                                                                  "Password"
                                                                ),
                                                            },
                                                            on: {
                                                              keyup:
                                                                _vm.handleKeyUpNewPassword,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.$v
                                                                  .newPassword
                                                                  .$model,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.$v
                                                                      .newPassword,
                                                                    "$model",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "$v.newPassword.$model",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              !_vm.$v
                                                                .newPassword
                                                                .$error
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn-show-password",
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.showNewPassword =
                                                                              !_vm.showNewPassword
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      !_vm.showNewPassword
                                                                        ? _c(
                                                                            _vm.getLucideIcon(
                                                                              "Eye"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .PERCENT
                                                                                      .color,
                                                                                  size: 18,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.showNewPassword
                                                                        ? _c(
                                                                            _vm.getLucideIcon(
                                                                              "EyeOff"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .PERCENT
                                                                                      .color,
                                                                                  size: 18,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.$v.newPassword
                                                                .$error === true
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "b-tooltip",
                                                                            rawName:
                                                                              "v-b-tooltip.click.bottomleft",
                                                                            value:
                                                                              _vm.FormMSG(
                                                                                498,
                                                                                "Password is required"
                                                                              ),
                                                                            expression:
                                                                              "FormMSG(498, 'Password is required')",
                                                                            modifiers:
                                                                              {
                                                                                click: true,
                                                                                bottomleft: true,
                                                                              },
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "btn-show-password border-red",
                                                                      staticStyle:
                                                                        {
                                                                          "border-left":
                                                                            "none !important",
                                                                        },
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "AlertTriangle",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.$v.newPassword.$error
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.925rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      477,
                                                                      "Password must contain minimum 8 characters and have at least one Upper case, one Lower case, one digit and one special character."
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm.authentificationFailed &&
                                          !_vm.askNewPassword
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-100 text-center py-2",
                                                  staticStyle: {
                                                    color: "red",
                                                    "font-size": "0.975rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          500,
                                                          "Your email or password is incorrect"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.askNewPassword
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "text-muted",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              1112,
                                                              "Password verification"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-input-group",
                                                    { staticClass: "mb-4" },
                                                    [
                                                      _c(
                                                        "b-input-group-prepend",
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "Lock"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .PERCENT
                                                                        .color,
                                                                    size: 18,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("b-form-input", {
                                                        staticClass:
                                                          "form-control br-none",
                                                        attrs: {
                                                          type: "password",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              1113,
                                                              "Re-enter Password"
                                                            ),
                                                        },
                                                        on: {
                                                          keyup:
                                                            _vm.handleVerificationNewPassword,
                                                          copy: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                          paste: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.passwordVerification,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.passwordVerification =
                                                              $$v
                                                          },
                                                          expression:
                                                            "passwordVerification",
                                                        },
                                                      }),
                                                      _vm.isNewPasswordIdenticalInvalid
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "invalid-feedback",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.925rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      981,
                                                                      "The two passwords must be the same."
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.askNewPassword && _vm.queryType.NU
                                            ? _c(
                                                "div",
                                                { staticClass: "mb-3" },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        value: true,
                                                        "unchecked-value": false,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.handleChangeCheckTermsOfService,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              81,
                                                              "I accept"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "false-link",
                                                          on: {
                                                            click:
                                                              _vm.handleClickTermsOfService,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                82,
                                                                "TheGreenshot's Terms of Service"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  !_vm.askNewPassword
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass: "px-4",
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            type: "submit",
                                                            variant: "primary",
                                                            disabled:
                                                              _vm.loginDisabled() ||
                                                              _vm.waitProcessSignin ||
                                                              _vm.waitingDoubleAuthentication(),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-center align-items-center",
                                                            },
                                                            [
                                                              _vm.waitProcessSignin
                                                                ? _c(
                                                                    "b-spinner",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Loading...",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2",
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "1px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        112,
                                                                        "Login"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "b-button",
                                                        {
                                                          staticClass: "px-4",
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            type: "button",
                                                            variant: "primary",
                                                            disabled:
                                                              _vm.$v.newPassword
                                                                .$error ||
                                                              _vm.passwordVerification.trim() ===
                                                                "" ||
                                                              _vm.disabledChangePassword,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleNewPassword,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-center align-items-center",
                                                            },
                                                            [
                                                              _vm.waitProcessSignin
                                                                ? _c(
                                                                    "b-spinner",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Loading...",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2",
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "1px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        942,
                                                                        "Change Password"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _vm.showRegisterOption
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "d-lg-none",
                                                      attrs: { md: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "active mt-3",
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.registerNow,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  107,
                                                                  "Register Now!"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.askNewPassword
                                                ? _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { md: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass: "px-0",
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            variant: "link",
                                                            disabled:
                                                              _vm.waitProcessSignin ||
                                                              _vm.requiredAppVersion !==
                                                                null,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleClickForgotPassword,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  105,
                                                                  "Forgot password?"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showRegisterOption
                                ? _c(
                                    "b-card",
                                    {
                                      staticClass:
                                        "card-border-blue-light text-white py-5 d-md-down-none",
                                      staticStyle: {
                                        width: "44%",
                                        "border-radius":
                                          "0 8px 8px 0 !important",
                                        "background-color":
                                          "rgba(0, 160, 156, 0.84) !important",
                                      },
                                      attrs: { "no-body": "" },
                                    },
                                    [
                                      _c(
                                        "b-card-body",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "letter-spacing": "0.05rem",
                                              },
                                            },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "pb-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        111,
                                                        "Sign up"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("p", [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        106,
                                                        "Sign up now to join and start working with TheGreenShot, track your timesheets, expenses, travels... and your carbon footprint."
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "active mt-3 px-4",
                                                  attrs: {
                                                    variant: "primary",
                                                    disabled:
                                                      _vm.waitProcessSignin ||
                                                      _vm.requiredAppVersion !==
                                                        null,
                                                  },
                                                  on: {
                                                    click: _vm.registerNow,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          107,
                                                          "Register Now!"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-modal",
                        {
                          staticClass: "modal-success",
                          attrs: {
                            "header-class": "header-class-modal-doc-package",
                            "ok-variant": "success",
                            "ok-only": "",
                            title: _vm.FormMSG(108, "Success!"),
                          },
                          on: {
                            ok: function ($event) {
                              _vm.successModal = false
                            },
                          },
                          model: {
                            value: _vm.successModal,
                            callback: function ($$v) {
                              _vm.successModal = $$v
                            },
                            expression: "successModal",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(
                                  109,
                                  "  An email has been sent to you. Open it and click on the provided link to reset your password."
                                )
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-modal",
                        {
                          staticClass: "modal-success",
                          attrs: {
                            "header-class": "header-class-modal-doc-package",
                            "ok-variant": "success",
                            "ok-only": "",
                            "no-close-on-backdrop": "",
                            "no-close-on-esc": "",
                            title: _vm.FormMSG(108, "Success!"),
                          },
                          on: {
                            ok: _vm.handleOkModalSuccessRegistration,
                            hidden: _vm.handleOkModalSuccessRegistration,
                          },
                          model: {
                            value: _vm.showModalSuccessRegistration,
                            callback: function ($$v) {
                              _vm.showModalSuccessRegistration = $$v
                            },
                            expression: "showModalSuccessRegistration",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.messageModalSuccessRegistration) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "b-modal",
                        {
                          staticClass: "modal-danger",
                          attrs: {
                            "header-class": "header-class-modal-doc-package",
                            "ok-variant": "danger",
                            "ok-only": "",
                            "no-close-on-backdrop": "",
                            "no-close-on-esc": "",
                            title: _vm.FormMSG(140, "Subscription Error!"),
                          },
                          on: {
                            ok: function ($event) {
                              _vm.showSubscriptionNotOrder = false
                            },
                            hidden: function ($event) {
                              _vm.showSubscriptionNotOrder = false
                            },
                          },
                          model: {
                            value: _vm.showSubscriptionNotOrder,
                            callback: function ($$v) {
                              _vm.showSubscriptionNotOrder = $$v
                            },
                            expression: "showSubscriptionNotOrder",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    141,
                                    "Your subscription is not in order, please call support to verify. All Solo+ projects are deactivated and your account is changed to Solo Free until your subscription returns to normal."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "b-modal",
                        {
                          staticClass: "modal-success",
                          attrs: {
                            "header-class": "header-class-modal-doc-package",
                            title: _vm.FormMSG(1282, "Validation code"),
                            "ok-variant": "success",
                            "ok-only": "",
                          },
                          on: { ok: _vm.closeModalInfoCode },
                          model: {
                            value: _vm.showModalInfoCode,
                            callback: function ($$v) {
                              _vm.showModalInfoCode = $$v
                            },
                            expression: "showModalInfoCode",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.messageModalInfoCode) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.forgotPassword
            ? _c(
                "div",
                [
                  _c("account-recovery", {
                    on: {
                      "account-recovery:next-step":
                        _vm.handleAccountRecoveryNextStep,
                      "cancel-recovery": _vm.cancelRecovery,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("error-app-version", {
            attrs: { "is-home": false, "last-version": _vm.version },
            on: {
              "error-app-version-modal:closed": _vm.onErrorAppVersionClosed,
            },
            model: {
              value: _vm.isErrorAppVersion,
              callback: function ($$v) {
                _vm.isErrorAppVersion = $$v
              },
              expression: "isErrorAppVersion",
            },
          }),
          _c("error-session-expired", {
            on: {
              "error-session-expired-modal:closed": function ($event) {
                _vm.isSessionExpired = false
              },
            },
            model: {
              value: _vm.isSessionExpired,
              callback: function ($$v) {
                _vm.isSessionExpired = $$v
              },
              expression: "isSessionExpired",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }