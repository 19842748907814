<template>
	<b-modal
		id="error-session-expired"
		header-class="header-class-modal-doc-package"
		:title="FormMSG(1, 'Error')"
		class="modal-success"
		v-model="isOpen"
		@hidden="emitEventClose"
		header-bg-variant="danger"
		header-border-variant="danger"
		ok-variant="light"
		ok-only
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div style="font-size: 1.1rem">
			<p>{{ FormMSG(2, 'your session expired, login again') }}</p>
		</div>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'ErrorSessionExpiredModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	methods: {
		emitEventClose() {
			this.$emit('error-session-expired-modal:closed');
		}
	}
};
</script>
<style lang="scss">
#error-session-expired .header-class-modal-doc-package h5 {
	color: #fff;
}
</style>
