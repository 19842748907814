var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-success",
      attrs: {
        id: "error-session-expired",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Error"),
        "header-bg-variant": "danger",
        "header-border-variant": "danger",
        "ok-variant": "light",
        "ok-only": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { hidden: _vm.emitEventClose },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("div", { staticStyle: { "font-size": "1.1rem" } }, [
        _c("p", [
          _vm._v(_vm._s(_vm.FormMSG(2, "your session expired, login again"))),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }