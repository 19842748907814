<template>
	<b-modal
		id="error-app-version"
		header-class="header-class-modal-doc-package"
		:title="FormMSG(1, 'Error')"
		class="modal-success"
		v-model="isOpen"
		@hidden="emitEventClose"
		header-bg-variant="danger"
		header-border-variant="danger"
		ok-variant="light"
		ok-only
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div style="font-size: 1.1rem" v-if="detectDevice !== DEVICE.WEB">
			<p>{{ FormMSG(2, 'Seems like you have some problems with the app version. Please download the last version.') }}</p>
			<p class="d-flex align-items-center">
				<a target="_blank" :href="linkDownload">{{ textDevice }}</a>
				<span>&nbsp;&nbsp;&nbsp;<component :is="getLucideIcon('ExternalLink')" color="#00A09C" :size="18" :stroke-width="2.25" /></span>
			</p>
			<p v-if="isHome === false">{{ FormMSG(3, 'Or, if you already have the right version installed. Please, try to reconnect once again.') }}</p>
		</div>
		<div style="font-size: 1.1rem" v-else>
			<p>{{ FormMSG(4, 'Seems like you have some problems with the app version. Please, try to reconnect once again.') }}</p>
		</div>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { DEVICE } from '@/shared/constants';

export default {
	name: 'ErrorAppVersionModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		lastVersion: {
			type: String,
			required: false,
			default: ''
		},
		isHome: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		detectDevice() {
			let device = DEVICE.WEB;

			if (this.$isIOSDevice()) {
				device = DEVICE.IOS;
			} else if (this.$isAndroidDevice()) {
				device = DEVICE.ANDROID;
			}

			return device;
		},
		textDevice() {
			let text = '';

			if (this.$isIOSDevice()) {
				text = 'App store';
			} else if (this.$isAndroidDevice()) {
				text = 'Play store';
			}

			return text;
		},
		linkDownload() {
			let link = '';

			if (this.$isIOSDevice()) {
				link = process.env.VUE_APP_APPLE_STORE ? process.env.VUE_APP_APPLE_STORE : this.appleStore;
			} else if (this.$isAndroidDevice()) {
				link = process.env.VUE_APP_PLAY_STORE ? process.env.VUE_APP_PLAY_STORE : this.playStore;
			}

			return link;
		}
	},
	data() {
		return {
			version: '',
			playStore: 'https://play.google.com/store/apps/details?id=green.thegreenshot.app',
			appleStore: 'https://apps.apple.com/fr/app/thegreenshot/id1591396215',
			DEVICE
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('error-app-version-modal:closed');
		}
	},
	watch: {
		lastVersion: {
			handler(value) {
				if (!_.isNil(value)) {
					this.version = value;
				}
			},
			deep: true,
			immediate: false
		}
	}
};
</script>
<style lang="scss">
#error-app-version .header-class-modal-doc-package h5 {
	color: #fff;
}
</style>
