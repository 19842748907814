var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-success",
      attrs: {
        id: "error-app-version",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Error"),
        "header-bg-variant": "danger",
        "header-border-variant": "danger",
        "ok-variant": "light",
        "ok-only": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { hidden: _vm.emitEventClose },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm.detectDevice !== _vm.DEVICE.WEB
        ? _c("div", { staticStyle: { "font-size": "1.1rem" } }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.FormMSG(
                    2,
                    "Seems like you have some problems with the app version. Please download the last version."
                  )
                )
              ),
            ]),
            _c("p", { staticClass: "d-flex align-items-center" }, [
              _c("a", { attrs: { target: "_blank", href: _vm.linkDownload } }, [
                _vm._v(_vm._s(_vm.textDevice)),
              ]),
              _c(
                "span",
                [
                  _vm._v("   "),
                  _c(_vm.getLucideIcon("ExternalLink"), {
                    tag: "component",
                    attrs: { color: "#00A09C", size: 18, "stroke-width": 2.25 },
                  }),
                ],
                1
              ),
            ]),
            _vm.isHome === false
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.FormMSG(
                        3,
                        "Or, if you already have the right version installed. Please, try to reconnect once again."
                      )
                    )
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticStyle: { "font-size": "1.1rem" } }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.FormMSG(
                    4,
                    "Seems like you have some problems with the app version. Please, try to reconnect once again."
                  )
                )
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }